import * as React from "react"
import { SVGProps } from "react"

const Logo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.2687 24.7247C12.2687 20.3474 12.2687 18.155 13.1243 16.482C13.8726 15.0092 15.068 13.8112 16.539 13.0596C18.212 12.2116 20.4045 12.2116 24.7894 12.2116H27.7229C32.1002 12.2116 34.2927 12.2116 35.9657 13.0596C37.439 13.8108 38.6369 15.0087 39.3881 16.482C40.2361 18.155 40.2361 20.3474 40.2361 24.7247V27.6353C40.2361 32.0126 40.2361 34.2051 39.3881 35.8781C38.6369 37.3514 37.439 38.5493 35.9657 39.3005C34.2927 40.1484 32.1002 40.1484 27.7229 40.1484H24.82C20.4351 40.1484 18.2426 40.1484 16.5696 39.3005C15.0985 38.5489 13.9032 37.3508 13.1548 35.8781C12.2992 34.2051 12.2992 32.0126 12.2992 27.6353L12.2687 24.7247Z"
			fill="#3021E5"
		/>
		<path
			d="M15.4313 30.9202C23.9538 30.9202 30.8627 24.0113 30.8627 15.4888C30.8627 6.96634 23.9538 0.0574951 15.4313 0.0574951C6.90885 0.0574951 0 6.96634 0 15.4888C0 24.0113 6.90885 30.9202 15.4313 30.9202Z"
			fill="#FC0B36"
		/>
		<path
			d="M18.0974 21.4169L21.367 18.1473L31.9703 30.0034L29.8466 32.1272L18.0974 21.4169Z"
			fill="white"
		/>
	</svg>
)

export default Logo
