import { HTMLAttributeAnchorTarget } from "react";

export interface PageLinkItem {
	text: string
	href: string
	useGatsbyLink: boolean
	target?: HTMLAttributeAnchorTarget
}

export const externalLinks = {
	blog: "https://blog.quickresto.ru/",
	demoCloud: {
		href: "https://demo.quickresto.ru/",
		text: "Демо режим",
	},
	demoWl: {
		href: "https://demowl.quickresto.ru/cpr/registration",
		useGatsbyLink: false,
	},
	demoWlsite: {
		text: "Посмотреть демо сайта",
		mobileText: "Демо сайта",
		href: "https://omlet.menuquickresto.ru/",
		useGatsbyLink: true,
	},
	vk: "https://vk.com/quickresto",
	facebook: "https://www.facebook.com/quickresto.ru",
	instagram: "https://www.instagram.com/quickresto/",
	youtube: "https://www.youtube.com/channel/UCWvHmsk_UwaKepM6oHoJJgw/",
	oneC: "https://1c.ru/",
	skolkovo: "https://sk.ru/",
	appstoreTerminal: "https://apps.apple.com/ru/app/quick-resto/id1463928023",
	appstoreQRM: "https://apps.apple.com/ru/app/quick-resto-manager/id1496593019", // "https://apps.apple.com/ru/app/quick-resto-manager/id1097815928",
	appstoreQRMRoistat:
		"https://apps.apple.com/ru/app/quick-resto-manager/id1496593019?roistat_visit=506676",
	appstoreAllApps:
		"https://apps.apple.com/ru/developer/quick-resto-ooo/id888261143",
	appstoreKds: "https://apps.apple.com/ru/app/quick-resto-kitchen/id1533258545",
	appstoreCustomerDisplay:
		"https://apps.apple.com/ru/app/quick-resto-display/id987939211?platform=ipad",
	googlePlayClientsCrm:
		"https://play.google.com/store/apps/developer?id=Quick+Resto&hl=ru",
	googlePlayQRM:
		"https://play.google.com/store/apps/details?id=ru.quickresto.manager&hl=ru",
	googlePlayQRMRoistat:
		"https://play.google.com/store/apps/details?id=ru.quickresto.manager&hl=ru&roistat_visit=506676",
	googlePlayLine:
		"https://play.google.com/store/apps/details?id=ru.quickresto.qrqueue",
	googlePlayKds:
		"https://play.google.com/store/apps/details?id=ru.quickresto.kds",
	googlePlayCustomerDisplay:
		"https://play.google.com/store/apps/details?id=ru.edgex.quickrestodisplay&hl=ru&gl=US",
	quickrestoRU: {
		href: "https://quickresto.ru/",
		text: "quickresto.ru",
	},
	quickrestoBY: {
		href: "https://quickresto.by/",
		//href: "https://by-quickresto.creonit.dev/",
		text: "quickresto.by",
	},
	quickrestoKZ: {
		href: "https://quickresto.kz/",
		//href: "https://kz-quickresto.creonit.dev/",
		text: "quickresto.kz",
	},
	quickrestoDG: {
		href: "mailto:ahmedradjabov83@gmail.com",
		text: "ahmedradjabov83@gmail.com",
	},
	quickrestoMSK: {
		href: "https://kafeline.com/",
		text: "Kafeline.com",
	},
	restoBusinessChat: {
		href: "https://t.me/resto_business/",
		useGatsbyLink: false,
	},
	supportBot: {
		href: "https://t.me/QuickResto_bot/",
		useGatsbyLink: false,
	},
	communityChat: {
		href: "https://t.me/QuickRestoCommunityBot",
		useGatsbyLink: false,
	},
	tgRestoChanel: "https://t.me/resto_business/",
	webinars: "https://myquickresto.ru/webinars/",

	// Страница 'Цены' блок "Чем еще поможем?"
	ofd: '/shop/uslugi/podklyuchenie_k_ofd/',
	proshivki: '/shop/uslugi/obnovlenie_proshivki_vnutrennego_po_atol/',
	lichnogo: '/shop/uslugi/nastroyka_lichnogo_kabineta_v_egais_dlya_torgovli_alkogolem/',
	fiskalnog: '/shop/uslugi/postavnovka_fiskalnogo_registratora_na_uchet/',

	podklyuchenieKOfd: "https://quickresto.ru/shop/uslugi/podklyuchenie_k_ofd/",
	obnovlenieProshivki:
		"https://quickresto.ru/shop/uslugi/obnovlenie_proshivki_vnutrennego_po_atol/",
	nastroykaLichnogo:
		"https://quickresto.ru/shop/uslugi/nastroyka_lichnogo_kabineta_v_egais_dlya_torgovli_alkogolem/",
	quickrestroPrice: "https://myquickresto.ru/price/",
	postavnovkaFiskalnog:
		"https://quickresto.ru/shop/uslugi/postavnovka_fiskalnogo_registratora_na_uchet/",
	swissam: "https://swissam.ru/",
	kotelrestomedia: "https://t.me/kotelrestomedia/",
}

export const pagesLinks = {
	index: {
		text: "",
		href: "/",
		useGatsbyLink: true,
	},
	restoGuide: {
		href: "https://guide.quickresto.ru/",
		text: "Справочник ресторатора",
		useGatsbyLink: false,
	},
	secretIngredient: {
		href: "/secret/",
		text: "Секретный ингредиент",
		useGatsbyLink: true,
	},
	support: {
		enterprise: {
			href: "/support/rabota_s_bek_ofisom/enterprise/enterprises/",
			useGatsbyLink: false,
		},
		venue: {
			href: "/support/rabota_s_bek_ofisom/enterprise/venues/",
			useGatsbyLink: false,
		},
		cookingplace: {
			href: "/support/rabota_s_bek_ofisom/enterprise/cookingplaces/",
			useGatsbyLink: false,
		},
		saleplace: {
			href: "/support/rabota_s_bek_ofisom/enterprise/saleplaces/",
			useGatsbyLink: false,
		},
		warehouse: {
			href: "/support/rabota_s_bek_ofisom/enterprise/warehouses/",
			useGatsbyLink: false,
		},
		dishes: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/dishes/",
			useGatsbyLink: false,
		},
		polufabrikaty: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/polufabrikaty/",
			useGatsbyLink: false,
		},
		modifikatory: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/modifikatory/",
			useGatsbyLink: false,
		},
		tovary: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/tovary/",
			useGatsbyLink: false,
		},
		versionnost_tekhnologicheskikh_kart: {
			href:
				"/support/rabota_s_bek_ofisom/nomenklatura/versionnost_tekhnologicheskikh_kart/",
			useGatsbyLink: false,
		},
		prikhodnye_nakladnye: {
			href: "/support/rabota_s_bek_ofisom/sklad/prikhodnye_nakladnye/",
			useGatsbyLink: false,
		},
		raskhodnye_nakladnye: {
			href: "/support/rabota_s_bek_ofisom/sklad/raskhodnye_nakladnye/",
			useGatsbyLink: false,
		},
		vnutrennie_peremeshcheniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/vnutrennie_peremeshcheniya/",
			useGatsbyLink: false,
		},
		akty_spisaniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_spisaniya/",
			useGatsbyLink: false,
		},
		akty_prigotovleniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_prigotovleniya/",
			useGatsbyLink: false,
		},
		inventarizatsiya: {
			href: "/support/rabota_s_bek_ofisom/sklad/inventarizatsiya/",
			useGatsbyLink: false,
		},
		akty_razbora: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_razbora/",
			useGatsbyLink: false,
		},
		reworking: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_razbora/#reworking/",
			useGatsbyLink: false,
		},
		tipy_oplat: {
			href: "/support/rabota_s_bek_ofisom/spravochniki/tipy_oplat/",
			useGatsbyLink: false,
		},
		fiksirovannye_skidki: {
			href: "/support/rabota_s_bek_ofisom/crm/discounts_/#fixed_discounts",
			useGatsbyLink: false,
		},
		shedule_discount: {
			href: "/support/rabota_s_bek_ofisom/crm/discounts_/#shedule_discount",
			useGatsbyLink: false,
		},
		bonusnye_programmy_: {
			href: "/support/rabota_s_bek_ofisom/crm/bonusnye_programmy_/",
			useGatsbyLink: false,
		},
		offers: {
			href: "/support/rabota_s_bek_ofisom/crm/offers/",
			useGatsbyLink: false,
		},
		nadbavki: {
			href: "/support/rabota_s_bek_ofisom/crm/nadbavki/",
			useGatsbyLink: false,
		},
		skidki_po_raspisaniyu: {
			href: "/support/rabota_s_bek_ofisom/crm/skidki_po_raspisaniyu/",
			useGatsbyLink: false,
		},
		defaultreports: {
			href: "/support/rabota_s_bek_ofisom/reports/defaultreports/",
			useGatsbyLink: false,
		},
		genreports: {
			href: "/support/rabota_s_bek_ofisom/reports/genreports/",
			useGatsbyLink: false,
		},
		text: "Поддержка",
		href: "/support/",
		useGatsbyLink: false,
	},
	capability: {
		href: "/capability/", // дублируется для компонента Breadcrumbs
		text: "Возможности",
		index: {
			text: "Возможности",
			href: "/capability/",
			useGatsbyLink: true,
		},
		warehouseSection: {
			href: "/capability/#warehouse",
			id: "warehouse",
			text: "Складской учёт",
			useGatsbyLink: true,
		},
		modifiersSection: {
			href: "/capability/#modifiers",
			id: "modifiers",
			text: "Техкарты",
			useGatsbyLink: true,
		},
		analyticsSection: {
			href: "/capability/#analytics",
			id: "analytics",
			text: "Аналитика",
			useGatsbyLink: true,
		},
		paymentTypesSection: {
			href: "/capability/#paymentTypes",
			id: "paymentTypes",
			text: "Типы оплат",
			useGatsbyLink: true,
		},
		loyaltySection: {
			href: "/capability/#loyalty",
			id: "loyalty",
			text: "CRM",
			useGatsbyLink: true,
		},
		supportSection: {
			href: "/capability/#support",
			id: "support",
			text: "Техническая поддержка 24/7",
			useGatsbyLink: true,
		},
		securitySection: {
			href: "/capability/#security",
			id: "security",
			text: "Безопасность",
			useGatsbyLink: true,
		},
		mobileAppsSection: {
			href: "/capability/#mobileApps",
			id: "mobileApps",
			text: "Приложения",
			useGatsbyLink: true,
		},
		integrationsSection: {
			href: "/capability/#integrations",
			id: "integrations",
			text: "Интеграции",
			useGatsbyLink: true,
		},
		shopCategoriesSection: {
			href: "/capability/#shopCategories",
			id: "shopCategories",
			text: "Оборудование",
			useGatsbyLink: true,
		},
	},
	shop: {
		index: {
			text: "Магазин",
			href: "/shop/",
			useGatsbyLink: false,
		},
		quickRestoSets: {
			text: "Quick Resto Set",
			href: "/shop/quick_resto_set/",
			useGatsbyLink: false,
		},
		qrBoxes: {
			text: "QR Box",
			href: "/shop/qr_box/",
			useGatsbyLink: false,
		},
		iPadRacks: {
			text: "Стойки для iPad",
			href: "/shop/stoyki_dlya_ipad/",
			useGatsbyLink: false,
		},
		fiscalRegistrars: {
			text: "Фискальные регистраторы",
			href: "/shop/fiskalnye_registratory/",
			useGatsbyLink: false,
		},
		ticketPrinters: {
			text: "Тикет-принтеры",
			href: "/shop/tiket_printery/",
			useGatsbyLink: false,
		},
		evotorTerminals: {
			text: "Смарт-терминалы Эвотор",
			href: "/shop/smart_terminal_evotor/",
			useGatsbyLink: false,
		},
		routers: {
			text: "Роутеры",
			href: "/shop/routery/",
			useGatsbyLink: false,
		},
		iPads: {
			text: "Apple iPad",
			href: "/shop/apple_ipad/",
			useGatsbyLink: false,
		},
		moneyBoxes: {
			text: "Денежные ящики",
			href: "/shop/denezhnyy_yashchik/",
			useGatsbyLink: false,
		},
		other: {
			text: "Прочее",
			href: "/shop/prochee/",
			useGatsbyLink: false,
		},
		services: {
			text: "Услуги",
			href: "/shop/uslugi/",
			useGatsbyLink: false,
			systemSetup: {
				href: "/shop/uslugi/ustanovka_i_nastroyka_sistemy_quick_resto/",
				useGatsbyLink: false,
			},
		},
	},
	blog: {
		text: "Блог",
		href: "https://blog.quickresto.ru/",
		useGatsbyLink: false,
	},
	lisenceAgreement: {
		text: "Лицензионное соглашение",
		href: "/licenseAgreement/",
		useGatsbyLink: true,
		annex: {
			href: "/annex/",
			useGatsbyLink: true,
			text: "Приложение №1",
		},
	},
	lisenceAgreementNotRu: {
		text: "Лицензионное соглашение",
		href: `${externalLinks.quickrestoRU.href}licenseAgreement/`,
		useGatsbyLink: false,
		target: '_blank',
		annex: {
			href: `${externalLinks.quickrestoRU.href}annex/`,
			useGatsbyLink: false,
			text: "Приложение №1",
			target: '_blank',
		},
	},
	lisenceAgreementBy: {
		text: "Лицензионное соглашение",
		href: `${externalLinks.quickrestoBY.href}licenseAgreement/`,
		useGatsbyLink: false,
		target: '_blank',
		annex: {
			href: `${externalLinks.quickrestoBY.href}annex/`,
			useGatsbyLink: false,
			text: "Приложение №1",
			target: '_blank',
		},
	},
	privacyPolicy: {
		text: "Политика конфиденциальности",
		href: "/privacy_policy/",
		useGatsbyLink: true,
	},
	privacyPolicyNotRu: {
		text: "Политика конфиденциальности",
		href: `${externalLinks.quickrestoRU.href}privacy_policy/`,
		useGatsbyLink: false,
		target: '_blank',
	},
	privacyPolicyBy: {
		text: "Политика конфиденциальности",
		href: `${externalLinks.quickrestoBY.href}privacy_policy/`,
		useGatsbyLink: false,
		target: '_blank',
	},
	about: {
		text: "О компании",
		href: "/about_company/",
		useGatsbyLink: true,
	},
	contacts: {
		text: "Контакты",
		href: "/contacts/",
		useGatsbyLink: true,
	},
	partners: {
		signUp: {
			href: "#kak-stat-partnerom-quick-resto",
		},
		text: "Партнерам",
		href: "https://quickresto.ru/partners/",
		useGatsbyLink: true,
	},

	deliveryAndPayment: {
		text: "Доставка и оплата",
		href: "/delivery-payment/",
		useGatsbyLink: false,
	},
	registration: {
		text: "Начать бесплатно",
		textKz: "Тегін бастау",
		href: "/registration/",
		useGatsbyLink: true,
	},
	service: {
		text: "Сервис Quick Resto",
		href: "/service/",
		id: "serviceForm",
		useGatsbyLink: true,
	},
	order: {
		text: "Заказать приложение",
		href: "/WLApplicationOrder/",
		useGatsbyLink: true,
	},
	login: {
		text: "Войти",
		textKz: "Кіру",
		href: "/login/",
		useGatsbyLink: true,
	},
	newPassword: {
		href: "/newPassword/",
		useGatsbyLink: true,
	},
	api: {
		text: "API",
		href: "/api/",
		useGatsbyLink: false,
	},
	personal: {
		text: "Личный кабинет",
		textKz: "Жеке кабинет",
		href: "/personal/",
	},
	price: {
		text: "Цены", // дублируется для компонента Breadcrumbs
		href: "/price/",
		index: {
			text: "Цены",
			href: "/price/",
			useGatsbyLink: true,
		},
		compare: {
			href: "/price/compare/",
			text: "Подробнее о тарифах и ценах",
			useGatsbyLink: true,
		},
	},
	businessTypes: {
		bar: {
			text: "Бар",
			href: "/automation/avtomatizaciya-bara/",
			useGatsbyLink: true,
		} as PageLinkItem,
		pub: {
			text: "Паб",
			href: "/automation/avtomatizaciya-paba/",
			useGatsbyLink: true,
		} as PageLinkItem,
		cafe: {
			text: "Кафе",
			href: "/automation/avtomatizaciya-kafe/",
			useGatsbyLink: true,
		} as PageLinkItem,
		cookery: {
			text: "Кулинария",
			href: "/automation/avtomatizaciya-kulinarii/",
			useGatsbyLink: true,
		} as PageLinkItem,
		restaurant: {
			text: "Ресторан",
			href: "/automation/avtomatizaciya-restorana/",
			useGatsbyLink: true,
		} as PageLinkItem,
		refectory: {
			text: "Столовая",
			href: "/automation/avtomatizaciya-stolovoy/",
			useGatsbyLink: true,
		} as PageLinkItem,
		fastfood: {
			text: "Фастфуд",
			href: "/automation/avtomatizaciya-fastfood/",
			useGatsbyLink: true,
		} as PageLinkItem,
		franchise: {
			text: "Франшиза",
			href: "/franchise/",
			useGatsbyLink: true,
		} as PageLinkItem,
		foodtruck: {
			text: "Фудтрак",
			href: "/automation/avtomatizaciya-fudtraka/",
			useGatsbyLink: true,
		} as PageLinkItem,
		coffeehouse: {
			text: "Кофейня",
			href: "/automation/avtomatizaciya-kofejni/",
			useGatsbyLink: true,
		} as PageLinkItem,
		bakery: {
			text: "Пекарня",
			href: "/automation/avtomatizaciya-pekarni/",
			useGatsbyLink: true,
		} as PageLinkItem,
		confectionery: {
			text: "Кондитерская",
			href: "/automation/avtomatizaciya-konditerskoy/",
			useGatsbyLink: true,
		} as PageLinkItem,
		multiformats: {
			text: "Мультиформаты",
			href: "/automation/multiformat/",
			useGatsbyLink: false,
		} as PageLinkItem,
		pizzeria: {
			text: "Пиццерия",
			href: "/automation/avtomatizaciya-piccerii/",
			useGatsbyLink: true,
		} as PageLinkItem,
		sushi: {
			text: "Суши",
			href: "/automation/avtomatizaciya-sushi/",
			useGatsbyLink: true,
		} as PageLinkItem,
		darkKitchen: {
			text: "Дарк-китчен",
			href: "/automation/dark-kitchen/",
			useGatsbyLink: true,
		} as PageLinkItem,
		stadiums: {
			text: "Стадионы и фестивали",
			href: "/automation/avtomatizaciya-stadiona/",
			useGatsbyLink: true,
		} as PageLinkItem,
		gastrobar: {
			text: "Гастробар",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		hookah: {
			text: "Кальян",
			href: "/automation/avtomatizaciya-kalyannoj/",
			useGatsbyLink: true,
		} as PageLinkItem,
		publicCatering: {
			text: "Общепита",
			href: "/automation/avtomatizaciya-obshchepita/",
			useGatsbyLink: true,
		} as PageLinkItem,
		forAdults: {
			text: "18+",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		stadium: {
			text: "Стадион",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		festival: {
			text: "Фестиваль",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		carWash: {
			text: "Автомойка",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
	},
	reviews: {
		madEspresso: {
			href: `${externalLinks.blog}mad_espresso_team_kak_gotovit_kofe/`,
		},
		vkusologia: {
			href: `${externalLinks.blog}fresh_bar/`,
		},
		bq: {
			href: `${externalLinks.blog}keys_myasobar_bk/`,
		},
		poke: {
			href: `${externalLinks.blog}poke_house_kak_zarabotat_na_neznakomoy_kukhne/`,
		},
		metallurgist: {
			href: `${externalLinks.blog}keys-khokkeynaya-arena-metallurg-mangitogorsk/`,
		},
		vkrutuyu: {
			href: `${externalLinks.blog}street-food/`,
		},
		zaymemsacofe: {
			href: `${externalLinks.blog}how-to-open-specialty-coffee-shop/`,
		},
		shegol: {
			href: `${externalLinks.blog}shchegol-coffee-shop-case/`,
		},
	},
	indexWL: {
		text: "Приложение для гостей",
		mobileText: "Назад",
		href: "/wl/",
		useGatsbyLink: true,
		// description: 'Приложение для гостей'
	},
	indexQRM: {
		href: "/qrm/",
		useGatsbyLink: true,
		description: "Приложение для руководителя",
	},
	indexKDS: {
		href: "/kds/",
		useGatsbyLink: true,
		description: "Кухонный экран",
	},
	terminal: {
		href: "/terminal/",
		useGatsbyLink: true,
		description: "Кассовый терминал",
		map: {
			href: "/terminal/#map",
			useGatsbyLink: true,
		},
		modifiers: {
			href: "/terminal/#modifiers",
			useGatsbyLink: true,
		},
	},
	bo: {
		text: "Бэк-офис",
		href: "/back-office/",
		useGatsbyLink: true,
		systemicApproach: {
			href: "/back-office/#systemicApproach",
			id: "systemicApproach",
			text: "CRM",
			useGatsbyLink: true,
		},
		techMapOrder: {
			href: "/back-office/#techMapOrder",
			id: "techMapOrder",
			text: "Меню",
			useGatsbyLink: true,
		},
		warehouseControl: {
			href: "/back-office/#warehouseControl",
			id: "warehouseControl",
			text: "Склад",
			useGatsbyLink: true,
		},
		analytics: {
			href: "/back-office/#analytics",
			id: "analytics",
			text: "Аналитика",
			useGatsbyLink: true,
		},
		defence: {
			href: "/back-office/#defence",
			id: "defence",
			useGatsbyLink: true,
		},
	},
	discounts: {
		text: "Акции",
		href: "/discounts/",
		index: {
			href: "/discounts/",
			text: "Акции",
			useGatsbyLink: true,
		},
		lite: {
			href: "/discounts/aktsiya_na_podpisku_tarif_lite/",
			useGatsbyLink: true,
			text: 'акция "Lite"',
		},
		pro: {
			href: "/discounts/stan_pro/",
			useGatsbyLink: true,
			text: 'акция "Pro"',
		},
		friend: {
			href: "/discounts/privedi_druga_2_0/",
			useGatsbyLink: true,
			text: 'акция "Приведи друга"',
		},
	},
	wlShop: {
		text: "Электронное меню",
		href: "/emenu/",
		useGatsbyLink: true,
		index: {
			href: "/emenu/",
			text: "Электронное меню",
			useGatsbyLink: true,
		},
		presentation: {
			text: "Заказать",
			useGatsbyLink: true,
			href: "/emenu#order",
		},
	},
	line: {
		href: "/line/",
		useGatsbyLink: true,
		text: "Электронная очередь",
	},
	finance: {
		href: "/finance/",
		useGatsbyLink: true,
	},
	reports: {
		href: "/reports/",
		useGatsbyLink: true,
		text: "",
		notifications: {
			href: "/reports/#notifications",
			useGatsbyLink: true,
		},
	},
	notifications: {
		href: "/notifications/",
		useGatsbyLink: true,
	},
	staff: {
		href: "/staff/",
		useGatsbyLink: true,
	},
	marketing: {
		text: "",
		href: "/marketing/",
		useGatsbyLink: true,
		crm: {
			href: "/marketing/#crm",
			useGatsbyLink: true,
		},
		discounts: {
			href: "/marketing/#discounts",
			useGatsbyLink: true,
		},
		bonus: {
			href: "/marketing/#bonus",
			useGatsbyLink: true,
		},
		specialOffer: {
			href: "/marketing/#special-offer",
			useGatsbyLink: true,
		},
	},
	webinars: {
		href: "/webinars/",
		useGatsbyLink: true,
	},
	automation: {
		href: "/automation/",
		useGatsbyLink: true,
	},
	passkit: {
		href: "/passkit/",
		useGatsbyLink: true,
	},
	delivery: {
		href: "/delivery/",
		useGatsbyLink: true,
	},
	deliveryClub: {
		href: "/delivery-club/",
		useGatsbyLink: true,
	},
	clients: {
		href: "/clients/",
		useGatsbyLink: true,
	},
	menu: {
		href: "/menu/",
		text: "",
		useGatsbyLink: true,
	},
	warehouse: {
		href: "/warehouse/",
		useGatsbyLink: true,
	},
	franchise: {
		href: "/franchise/",
		useGatsbyLink: true,
	},
	customerDisplay: {
		href: "/customer-display/",
		useGatsbyLink: true,
	},
	yandex: {
		href: "/yandex/",
		useGatsbyLink: true,
	},
	integrations: {
		text: "Интеграции",
		href: "/integrations/",
		useGatsbyLink: true,
	},
}

export const pagesLinksKz = {
	index: {
		text: "",
		href: "/",
		useGatsbyLink: true,
	},
	restoGuide: {
		href: "https://guide.quickresto.ru/",
		text: "Справочник ресторатора",
		useGatsbyLink: false,
	},
	secretIngredient: {
		href: "/secret/",
		text: "Секретный ингредиент",
		useGatsbyLink: true,
	},
	support: {
		enterprise: {
			href: "/support/rabota_s_bek_ofisom/enterprise/enterprises/",
			useGatsbyLink: false,
		},
		venue: {
			href: "/support/rabota_s_bek_ofisom/enterprise/venues/",
			useGatsbyLink: false,
		},
		cookingplace: {
			href: "/support/rabota_s_bek_ofisom/enterprise/cookingplaces/",
			useGatsbyLink: false,
		},
		saleplace: {
			href: "/support/rabota_s_bek_ofisom/enterprise/saleplaces/",
			useGatsbyLink: false,
		},
		warehouse: {
			href: "/support/rabota_s_bek_ofisom/enterprise/warehouses/",
			useGatsbyLink: false,
		},
		dishes: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/dishes/",
			useGatsbyLink: false,
		},
		polufabrikaty: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/polufabrikaty/",
			useGatsbyLink: false,
		},
		modifikatory: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/modifikatory/",
			useGatsbyLink: false,
		},
		tovary: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/tovary/",
			useGatsbyLink: false,
		},
		versionnost_tekhnologicheskikh_kart: {
			href:
				"/support/rabota_s_bek_ofisom/nomenklatura/versionnost_tekhnologicheskikh_kart/",
			useGatsbyLink: false,
		},
		prikhodnye_nakladnye: {
			href: "/support/rabota_s_bek_ofisom/sklad/prikhodnye_nakladnye/",
			useGatsbyLink: false,
		},
		raskhodnye_nakladnye: {
			href: "/support/rabota_s_bek_ofisom/sklad/raskhodnye_nakladnye/",
			useGatsbyLink: false,
		},
		vnutrennie_peremeshcheniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/vnutrennie_peremeshcheniya/",
			useGatsbyLink: false,
		},
		akty_spisaniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_spisaniya/",
			useGatsbyLink: false,
		},
		akty_prigotovleniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_prigotovleniya/",
			useGatsbyLink: false,
		},
		inventarizatsiya: {
			href: "/support/rabota_s_bek_ofisom/sklad/inventarizatsiya/",
			useGatsbyLink: false,
		},
		akty_razbora: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_razbora/",
			useGatsbyLink: false,
		},
		reworking: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_razbora/#reworking/",
			useGatsbyLink: false,
		},
		tipy_oplat: {
			href: "/support/rabota_s_bek_ofisom/spravochniki/tipy_oplat/",
			useGatsbyLink: false,
		},
		fiksirovannye_skidki: {
			href: "/support/rabota_s_bek_ofisom/crm/discounts_/#fixed_discounts",
			useGatsbyLink: false,
		},
		shedule_discount: {
			href: "/support/rabota_s_bek_ofisom/crm/discounts_/#shedule_discount",
			useGatsbyLink: false,
		},
		bonusnye_programmy_: {
			href: "/support/rabota_s_bek_ofisom/crm/bonusnye_programmy_/",
			useGatsbyLink: false,
		},
		offers: {
			href: "/support/rabota_s_bek_ofisom/crm/offers/",
			useGatsbyLink: false,
		},
		nadbavki: {
			href: "/support/rabota_s_bek_ofisom/crm/nadbavki/",
			useGatsbyLink: false,
		},
		skidki_po_raspisaniyu: {
			href: "/support/rabota_s_bek_ofisom/crm/skidki_po_raspisaniyu/",
			useGatsbyLink: false,
		},
		defaultreports: {
			href: "/support/rabota_s_bek_ofisom/reports/defaultreports/",
			useGatsbyLink: false,
		},
		genreports: {
			href: "/support/rabota_s_bek_ofisom/reports/genreports/",
			useGatsbyLink: false,
		},
		text: "Қолдау",
		href: "/support/",
		useGatsbyLink: false,
	},
	capability: {
		href: "/capability/", // дублируется для компонента Breadcrumbs
		text: "Мүмкіндіктер",
		index: {
			text: "Мүмкіндіктер",
			href: "/capability/",
			useGatsbyLink: true,
		},
		warehouseSection: {
			href: "/capability/#warehouse",
			id: "warehouse",
			text: "Складской учёт",
			useGatsbyLink: true,
		},
		modifiersSection: {
			href: "/capability/#modifiers",
			id: "modifiers",
			text: "Техкарты",
			useGatsbyLink: true,
		},
		analyticsSection: {
			href: "/capability/#analytics",
			id: "analytics",
			text: "Аналитика",
			useGatsbyLink: true,
		},
		paymentTypesSection: {
			href: "/capability/#paymentTypes",
			id: "paymentTypes",
			text: "Типы оплат",
			useGatsbyLink: true,
		},
		loyaltySection: {
			href: "/capability/#loyalty",
			id: "loyalty",
			text: "CRM",
			useGatsbyLink: true,
		},
		supportSection: {
			href: "/capability/#support",
			id: "support",
			text: "Техническая поддержка 24/7",
			useGatsbyLink: true,
		},
		securitySection: {
			href: "/capability/#security",
			id: "security",
			text: "Безопасность",
			useGatsbyLink: true,
		},
		mobileAppsSection: {
			href: "/capability/#mobileApps",
			id: "mobileApps",
			text: "Приложения",
			useGatsbyLink: true,
		},
		integrationsSection: {
			href: "/capability/#integrations",
			id: "integrations",
			text: "Интеграции",
			useGatsbyLink: true,
		},
		shopCategoriesSection: {
			href: "/capability/#shopCategories",
			id: "shopCategories",
			text: "Оборудование",
			useGatsbyLink: true,
		},
	},
	shop: {
		index: {
			text: "Дүкен",
			href: "/shop/",
			useGatsbyLink: false,
		},
		quickRestoSets: {
			text: "Quick Resto Set",
			href: "/shop/quick_resto_set/",
			useGatsbyLink: false,
		},
		qrBoxes: {
			text: "QR Box",
			href: "/shop/qr_box/",
			useGatsbyLink: false,
		},
		iPadRacks: {
			text: "Стойки для iPad",
			href: "/shop/stoyki_dlya_ipad/",
			useGatsbyLink: false,
		},
		fiscalRegistrars: {
			text: "Фискальные регистраторы",
			href: "/shop/fiskalnye_registratory/",
			useGatsbyLink: false,
		},
		ticketPrinters: {
			text: "Тикет-принтеры",
			href: "/shop/tiket_printery/",
			useGatsbyLink: false,
		},
		evotorTerminals: {
			text: "Смарт-терминалы Эвотор",
			href: "/shop/smart_terminal_evotor/",
			useGatsbyLink: false,
		},
		routers: {
			text: "Роутеры",
			href: "/shop/routery/",
			useGatsbyLink: false,
		},
		iPads: {
			text: "Apple iPad",
			href: "/shop/apple_ipad/",
			useGatsbyLink: false,
		},
		moneyBoxes: {
			text: "Денежные ящики",
			href: "/shop/denezhnyy_yashchik/",
			useGatsbyLink: false,
		},
		other: {
			text: "Прочее",
			href: "/shop/prochee/",
			useGatsbyLink: false,
		},
		services: {
			text: "Услуги",
			href: "/shop/uslugi/",
			useGatsbyLink: false,
			systemSetup: {
				href: "/shop/uslugi/ustanovka_i_nastroyka_sistemy_quick_resto/",
				useGatsbyLink: false,
			},
		},
	},
	blog: {
		text: "Блог",
		href: "https://blog.quickresto.ru/",
		useGatsbyLink: false,
	},
	lisenceAgreement: {
		text: "Лицензионное соглашение",
		href: "/licenseAgreement/",
		useGatsbyLink: true,
		annex: {
			href: "/annex/",
			useGatsbyLink: true,
			text: "Приложение №1",
		},
	},
	lisenceAgreementKz: {
		text: "Лицензиялық келісім",
		href: `/licenseAgreement/`,
		useGatsbyLink: false,
		target: '_blank',
		annex: {
			href: `${externalLinks.quickrestoRU.href}annex/`,
			useGatsbyLink: false,
			text: "Приложение №1",
			target: '_blank',
		},
	},
	lisenceAgreementBy: {
		text: "Лицензионное соглашение",
		href: `${externalLinks.quickrestoBY.href}licenseAgreement/`,
		useGatsbyLink: false,
		target: '_blank',
		annex: {
			href: `${externalLinks.quickrestoBY.href}annex/`,
			useGatsbyLink: false,
			text: "Приложение №1",
			target: '_blank',
		},
	},
	privacyPolicy: {
		text: "Политика конфиденциальности",
		href: "/privacy_policy/",
		useGatsbyLink: true,
	},
	privacyPolicyNotRu: {
		text: "Құпиялылық саясаты",
		href: `${externalLinks.quickrestoRU.href}privacy_policy/`,
		useGatsbyLink: false,
		target: '_blank',
	},
	privacyPolicyBy: {
		text: "Политика конфиденциальности",
		href: `${externalLinks.quickrestoBY.href}privacy_policy/`,
		useGatsbyLink: false,
		target: '_blank',
	},
	about: {
		text: "Компания туралы",
		href: "/about_company/",
		useGatsbyLink: true,
	},
	contacts: {
		text: "Байланыс деректері",
		href: "/contacts/",
		useGatsbyLink: true,
	},
	partners: {
		signUp: {
			href: "#kak-stat-partnerom-quick-resto",
		},
		text: "Серіктестерге",
		href: "https://quickresto.ru/partners/",
		useGatsbyLink: true,
	},

	deliveryAndPayment: {
		text: "Жеткізу және төлеу",
		href: "/delivery-payment/",
		useGatsbyLink: false,
	},
	registration: {
		text: "Тегін бастау",
		textKz: "Тегін бастау",
		href: "/registration/",
		useGatsbyLink: true,
	},
	service: {
		text: "Сервис Quick Resto",
		href: "/service/",
		id: "serviceForm",
		useGatsbyLink: true,
	},
	order: {
		text: "Заказать приложение",
		href: "/WLApplicationOrder/",
		useGatsbyLink: true,
	},
	login: {
		text: "Войти",
		textKz: "Кіру",
		href: "/login/",
		useGatsbyLink: true,
	},
	newPassword: {
		href: "/newPassword/",
		useGatsbyLink: true,
	},
	api: {
		text: "API",
		href: "/api/",
		useGatsbyLink: false,
	},
	personal: {
		text: "Личный кабинет",
		textKz: "Жеке кабинет",
		href: "/personal/",
	},
	price: {
		text: "Бағалар", // дублируется для компонента Breadcrumbs
		href: "/price/",
		index: {
			text: "Бағалар",
			href: "/price/",
			useGatsbyLink: true,
		},
		compare: {
			href: "/price/compare/",
			text: "Подробнее о тарифах и ценах",
			useGatsbyLink: true,
		},
	},
	businessTypes: {
		bar: {
			text: "Бар",
			href: "/automation/avtomatizaciya-bara/",
			useGatsbyLink: true,
		} as PageLinkItem,
		pub: {
			text: "Паб",
			href: "/automation/avtomatizaciya-paba/",
			useGatsbyLink: true,
		} as PageLinkItem,
		cafe: {
			text: "Кафе",
			href: "/automation/avtomatizaciya-kafe/",
			useGatsbyLink: true,
		} as PageLinkItem,
		cookery: {
			text: "Аспаздық",
			href: "/automation/avtomatizaciya-kulinarii/",
			useGatsbyLink: true,
		} as PageLinkItem,
		restaurant: {
			text: "Мейрамхана",
			href: "/automation/avtomatizaciya-restorana/",
			useGatsbyLink: true,
		} as PageLinkItem,
		refectory: {
			text: "Асхана",
			href: "/automation/avtomatizaciya-stolovoy/",
			useGatsbyLink: true,
		} as PageLinkItem,
		fastfood: {
			text: "Фастфуд",
			href: "/automation/avtomatizaciya-fastfood/",
			useGatsbyLink: true,
		} as PageLinkItem,
		franchise: {
			text: "Франшиза",
			href: "/franchise/",
			useGatsbyLink: true,
		} as PageLinkItem,
		foodtruck: {
			text: "Фудтрак",
			href: "/automation/avtomatizaciya-fudtraka/",
			useGatsbyLink: true,
		} as PageLinkItem,
		coffeehouse: {
			text: "Кофехана",
			href: "/automation/avtomatizaciya-kofejni/",
			useGatsbyLink: true,
		} as PageLinkItem,
		bakery: {
			text: "Пекарня",
			href: "/automation/avtomatizaciya-pekarni/",
			useGatsbyLink: true,
		} as PageLinkItem,
		confectionery: {
			text: "Кондитерская",
			href: "/automation/avtomatizaciya-konditerskoy/",
			useGatsbyLink: true,
		} as PageLinkItem,
		multiformats: {
			text: "Мультиформаттар",
			href: "/automation/multiformat/",
			useGatsbyLink: false,
		} as PageLinkItem,
		pizzeria: {
			text: "Пиццерия",
			href: "/automation/avtomatizaciya-piccerii/",
			useGatsbyLink: true,
		} as PageLinkItem,
		sushi: {
			text: "Суши",
			href: "/automation/avtomatizaciya-sushi/",
			useGatsbyLink: true,
		} as PageLinkItem,
		darkKitchen: {
			text: "Дарк-китчен",
			href: "/automation/dark-kitchen/",
			useGatsbyLink: true,
		} as PageLinkItem,
		stadiums: {
			text: "Стадионы и фестивали",
			href: "/automation/avtomatizaciya-stadiona/",
			useGatsbyLink: true,
		} as PageLinkItem,
		gastrobar: {
			text: "Гастробар",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		hookah: {
			text: "Кальян",
			href: "/automation/avtomatizaciya-kalyannoj/",
			useGatsbyLink: true,
		} as PageLinkItem,
		publicCatering: {
			text: "Общепита",
			href: "/automation/avtomatizaciya-obshchepita/",
			useGatsbyLink: true,
		} as PageLinkItem,
		forAdults: {
			text: "18+",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		stadium: {
			text: "Стадион",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		festival: {
			text: "Фестиваль",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
		carWash: {
			text: "Автомойка",
			// TODO CONTENT. Антоха подгонит инфу
			href: "/",
			useGatsbyLink: true,
		} as PageLinkItem,
	},
	reviews: {
		madEspresso: {
			href: `${externalLinks.blog}mad_espresso_team_kak_gotovit_kofe/`,
		},
		vkusologia: {
			href: `${externalLinks.blog}fresh_bar/`,
		},
		bq: {
			href: `${externalLinks.blog}keys_myasobar_bk/`,
		},
		poke: {
			href: `${externalLinks.blog}poke_house_kak_zarabotat_na_neznakomoy_kukhne/`,
		},
		metallurgist: {
			href: `${externalLinks.blog}keys-khokkeynaya-arena-metallurg-mangitogorsk/`,
		},
		vkrutuyu: {
			href: `${externalLinks.blog}street-food/`,
		},
		zaymemsacofe: {
			href: `${externalLinks.blog}how-to-open-specialty-coffee-shop/`,
		},
		shegol: {
			href: `${externalLinks.blog}shchegol-coffee-shop-case/`,
		},
	},
	indexWL: {
		text: "Приложение для гостей",
		mobileText: "Назад",
		href: "/wl/",
		useGatsbyLink: true,
		// description: 'Приложение для гостей'
	},
	indexQRM: {
		href: "/qrm/",
		useGatsbyLink: true,
		description: "Приложение для руководителя",
	},
	indexKDS: {
		href: "/kds/",
		useGatsbyLink: true,
		description: "Кухонный экран",
	},
	terminal: {
		href: "/terminal/",
		useGatsbyLink: true,
		description: "Кассовый терминал",
		map: {
			href: "/terminal/#map",
			useGatsbyLink: true,
		},
		modifiers: {
			href: "/terminal/#modifiers",
			useGatsbyLink: true,
		},
	},
	bo: {
		text: "Бэк-офис",
		href: "/back-office/",
		useGatsbyLink: true,
		systemicApproach: {
			href: "/back-office/#systemicApproach",
			id: "systemicApproach",
			text: "CRM",
			useGatsbyLink: true,
		},
		techMapOrder: {
			href: "/back-office/#techMapOrder",
			id: "techMapOrder",
			text: "Меню",
			useGatsbyLink: true,
		},
		warehouseControl: {
			href: "/back-office/#warehouseControl",
			id: "warehouseControl",
			text: "Склад",
			useGatsbyLink: true,
		},
		analytics: {
			href: "/back-office/#analytics",
			id: "analytics",
			text: "Аналитика",
			useGatsbyLink: true,
		},
		defence: {
			href: "/back-office/#defence",
			id: "defence",
			useGatsbyLink: true,
		},
	},
	discounts: {
		text: "Акциялар",
		href: "/discounts/",
		index: {
			href: "/discounts/",
			text: "Акциялар",
			useGatsbyLink: true,
		},
		lite: {
			href: "/discounts/aktsiya_na_podpisku_tarif_lite/",
			useGatsbyLink: true,
			text: 'акция "Lite"',
		},
		pro: {
			href: "/discounts/stan_pro/",
			useGatsbyLink: true,
			text: 'акция "Pro"',
		},
		friend: {
			href: "/discounts/privedi_druga_2_0/",
			useGatsbyLink: true,
			text: 'акция "Приведи друга"',
		},
	},
	wlShop: {
		text: "Электронное меню",
		href: "/emenu/",
		useGatsbyLink: true,
		index: {
			href: "/emenu/",
			text: "Электронное меню",
			useGatsbyLink: true,
		},
		presentation: {
			text: "Заказать",
			useGatsbyLink: true,
			href: "/emenu#order",
		},
	},
	line: {
		href: "/line/",
		useGatsbyLink: true,
		text: "Электронная очередь",
	},
	finance: {
		href: "/finance/",
		useGatsbyLink: true,
	},
	reports: {
		href: "/reports/",
		useGatsbyLink: true,
		text: "",
		notifications: {
			href: "/reports/#notifications",
			useGatsbyLink: true,
		},
	},
	notifications: {
		href: "/notifications/",
		useGatsbyLink: true,
	},
	staff: {
		href: "/staff/",
		useGatsbyLink: true,
	},
	marketing: {
		text: "",
		href: "/marketing/",
		useGatsbyLink: true,
		crm: {
			href: "/marketing/#crm",
			useGatsbyLink: true,
		},
		discounts: {
			href: "/marketing/#discounts",
			useGatsbyLink: true,
		},
		bonus: {
			href: "/marketing/#bonus",
			useGatsbyLink: true,
		},
		specialOffer: {
			href: "/marketing/#special-offer",
			useGatsbyLink: true,
		},
	},
	webinars: {
		href: "/webinars/",
		useGatsbyLink: true,
	},
	automation: {
		href: "/automation/",
		useGatsbyLink: true,
	},
	passkit: {
		href: "/passkit/",
		useGatsbyLink: true,
	},
	delivery: {
		href: "/delivery/",
		useGatsbyLink: true,
	},
	deliveryClub: {
		href: "/delivery-club/",
		useGatsbyLink: true,
	},
	clients: {
		href: "/clients/",
		useGatsbyLink: true,
	},
	menu: {
		href: "/menu/",
		text: "",
		useGatsbyLink: true,
	},
	warehouse: {
		href: "/warehouse/",
		useGatsbyLink: true,
	},
	franchise: {
		href: "/franchise/",
		useGatsbyLink: true,
	},
	customerDisplay: {
		href: "/customer-display/",
		useGatsbyLink: true,
	},
	yandex: {
		href: "/yandex/",
		useGatsbyLink: true,
	},
	integrations: {
		text: "Интеграциялар",
		href: "/integrations/",
		useGatsbyLink: true,
	},
}
