import React, { MouseEvent } from "react"

import { Link } from "gatsby"

export interface AdaptiveLinkBaseProps {
	useGatsbyLink?: boolean
	href: string
	rel?: string
	target?: string
	title?: string
}

export interface AdaptiveLinkProps extends AdaptiveLinkBaseProps {
	className?: string

	itemprop?: string

	children: JSX.Element | Array<JSX.Element> | string

	clickHandle?: Function
}

export default function AdaptiveLink(props: AdaptiveLinkProps) {
	const clickHandle = (evt: MouseEvent) => {
		if (props.href.includes("#popup") && props.clickHandle) {
			evt.preventDefault()
			props.clickHandle(evt)
		}
	}

	return props.useGatsbyLink ? (
		<Link
			className={props.className || ""}
			to={props.href}
			rel={props.rel}
			title={props.title}
			// @ts-ignore
			itemProp={props.itemprop || null}
			target={props.target}
		>
			{props.children}
		</Link>
	) : (
		<a
			className={props.className || ""}
			href={props.href}
			rel={props.rel}
			title={props.title}
			// @ts-ignore
			itemProp={props.itemprop || null}
			target={props.target}
			onClick={clickHandle}
		>
			{props.children}
		</a>
	)
}
